document.addEventListener('wpcf7mailsent', function(event) {
    location = '/contact/thanks/';
  }, false);

  const back = document.querySelector(".wpcf7c-btn-back");
  if(back) {
      back.addEventListener("click", () => {
        document.querySelector(".wpcf7-form").classList.remove("custom-wpcf7c-confirmed");
      });
  }

//   $(".wpcf7c-btn-back").click(function () {
//     $('.wpcf7-form').removeClass('custom-wpcf7c-confirmed');
//     $('.step2').removeClass('-on');
//     $('.step1').addClass('-on');
//   });