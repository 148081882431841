import swiper from "swiper";

if ($(".js-hero-slider").length) {
  var slider = new swiper(".js-hero-slider", {
    slidesPerView: "auto",
    loop: true,
    speed: 1500,

    autoplay: {
      delay: 7000,
      disableOnInteraction: false,
    },
    effect: "fade",
    fade: {
      crossFade: true,
    },
  });
}
