import swiper from "swiper";

const length = document.querySelectorAll(".swiper-slide").length;
const next = document.querySelector(".js-product-slider-next");
const prev = document.querySelector(".js-product-slider-prev");
if ($(".js-product-slider").length && length > 1) {
  var slider = new swiper(".js-product-slider", {
    slidesPerView: 2,
    loop: true,
    speed: 1000,
    spaceBetween: 45,
    centeredSlides: true,
    allowTouchMove: false,
    pagination: {
      el: ".js-product-slider-pagination",
    },
    navigation: {
      nextEl: ".js-product-slider-next",
      prevEl: ".js-product-slider-prev",
    },
    breakpoints: {
      768: {
        slidesPerView: "auto",
        allowTouchMove: true,
      },
    },
  });
} else if (next && prev) {
  prev.style.display = "none";
  next.style.display = "none";
}

if ($(".js-top-product-slider").length) {
  var slider = new swiper(".js-top-product-slider", {
    slidesPerView: "auto",
    loop: true,
    speed: 1000,
    spaceBetween: 20,
    // centeredSlides: true,
    allowTouchMove: false,
    navigation: {
      nextEl: ".js-top-product-slider-next",
      prevEl: ".js-top-product-slider-prev",
    },
    breakpoints: {
      768: {
        slidesPerView: 2.5,
        allowTouchMove: true,
        centeredSlides: true,
      },
    },
  });
}
