import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

window.addEventListener("load", () => {
  letterSetting();
  fvLoading();
  EachTextAnimeControl_span();
  flowScroll();
});

function letterSetting() {
  const lt = document.querySelector(".js-lt");
  if (lt) {
    let temp = lt.innerText.replace(/(\S)/g, "<span>$1</span>");
    let result = temp.replace(/(\s)/g, "<span>&nbsp;</span>");

    lt.innerHTML = result;
  }
}

function fvLoading() {
  const pathStart =
    "M0.0,0.0 L1000.0,0.0 L1000.0,1000.0 C1000.0,1000.0 750.475,1000.0 500.714,1000.0 C250.476,1000.0 0.0,1000.0 0.0,1000.0 L0.0,0.0 Z";
  const pathMiddle =
    "M0.0,0.0 L1000.0,0.0 L1000.0,1000.0 C1000.0,1000.0 750.475,759.999 500.714,759.999 C250.476,759.999 0.0,1000.0 0.0,1000.0 L0.0,0.0 Z";
  const pathEnd =
    "M0.0,0.0 L1000.0,0.0 L1000.0,1.0 C1000.0,1.0 750.475,1.0 500.714,1.0 C250.476,1.0 0.0,1.0 0.0,1.0 L0.0,0.0 Z";

  let loader = gsap.timeline({});

  if (document.querySelectorAll(".js-loading__txt span").length) {
    loader.fromTo(
      ".js-loading__txt span",
      {
        y: $(".js-loading__txt span").height(),
        autoAlpha: 1,
        scale: 0.6,
      },
      {
        duration: 0.4,
        y: 0,
        scale: 1,
        ease: "expo.out",
        stagger: { from: "start", amount: 0.5 },
      }
    );

    loader.to(".mask2", {
      duration: 0,
      ease: "power1.in",
      attr: {
        d: pathStart,
      },
    });
    loader.to(".mask", {
      duration: 0,
      ease: "power1.in",
      attr: {
        d: pathStart,
      },
    });
    loader.to(".l-load__body-logo", {
      duration: 0.3,
      ease: "power1.in",
      opacity: 0,
      y: "-30%",
    });
    loader.to(
      ".mask",
      {
        duration: 0.55,
        ease: "power4.in",
        attr: {
          d: pathMiddle,
        },
      },
      "< 0"
    );
    loader.to(
      ".mask2",
      {
        duration: 0.55,
        ease: "power4.in",
        attr: {
          d: pathMiddle,
        },
      },
      "< 0.05"
    );
    loader.to(
      ".mask",
      {
        duration: 1.25,
        ease: "expo.out",
        attr: {
          d: pathEnd,
        },
      },
      "< 0.5"
    );
    loader.to(
      ".mask2",
      {
        duration: 1.25,
        ease: "expo.out",
        attr: {
          d: pathEnd,
        },
        onComplete: doAnimation,
      },
      "<0.05"
    );

    function scrollEffects() {
      gsap.from(".p-top-strong .p-body-img-col2-list__item-right p", {
        scrollTrigger: {
          trigger: ".p-top-strong-head",
          start: "top 50%",
        },
        y: "5%",
        opacity: 0,
        duration: 0.6,
        ease: "power1",
        stagger: "0.2",
      });

      gsap.from(".p-top-strong .p-body-img-col2-list__item-left img", {
        scrollTrigger: {
          trigger: ".p-top-strong-head",
          start: "top 50%",
        },
        y: "15%",
        opacity: 0,
        duration: 0.6,
        ease: "power1",
        stagger: "0.2",
      });

      gsap.from(".p-top-flow-map-body-map img", {
        scrollTrigger: {
          trigger: ".p-top-flow-map-head",
          start: "top 50%",
        },
        y: "15%",
        opacity: 0,
        duration: 0.6,
        ease: "power1",
        stagger: "0.2",
      });
    }

    scrollEffects();
  }
}

function EachTextAnimeControl_span() {
  //spanタグを追加する
  let index = 0;
  document.querySelectorAll(".text-anime01 span").forEach((el) => {
    const text = el.innerHTML;
    el.innerHTML = "";
    text
      .split("")
      .map((t) => {
        const span = document.createElement("span");
        if (t !== " ") {
          span.style.animationDelay = `${index++ / 10 + 2}s`;
          span.innerHTML = t;
          return span;
        } else {
          return t;
        }
      })
      .forEach((s) => el.insertAdjacentElement("beforeend", s));
  });
}

function doAnimation() {
  document.querySelectorAll(".text-anime01").forEach((e) => {
    e.classList.add("appeartext");
  });
}

function flowScroll() {
  if (document.querySelector(".p-company-flow-main-step")) {
    new Array(7).fill("").forEach((_, index) => {
      const option = {
        scrollTrigger: {
          trigger: `.p-company-flow-main-list__item.--step${index + 1}`,
          start: "top 10%",
          toggleClass: {
            targets: `.p-company-flow-main-step-list__item.--step${index + 1}`,
          },
        },
        ease: "power1",
        stagger: "0.2",
      };

      const option1 = JSON.parse(JSON.stringify(option));
      option1.scrollTrigger.end = "bottom+=10000px top";
      option1.scrollTrigger.toggleClass.className = "complete";

      gsap.to(
        `.p-company-flow-main-step-list__item.--step${index + 1}`,
        option1
      );

      const option2 = JSON.parse(JSON.stringify(option));
      option2.scrollTrigger.toggleClass.className = "current";
      option2.scrollTrigger.end = "top -10%";

      gsap.to(
        `.p-company-flow-main-step-list__item.--step${index + 1}`,
        option2
      );
    });
  }
}
