// import {TweenMax} from "gsap/TweenMax";
import ScrollMagic from "scrollmagic";
// import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';


var controller = new ScrollMagic.Controller();

var scene_hero = new ScrollMagic.Scene({
  triggerElement: "body",
  triggerHook: 'onEnter',
  // duration: 800,
  offset: 0
})
  // .addIndicators()
  .addTo(controller);

scene_hero.on('enter', () => {
  //triggerElementを過ぎたとき
});
scene_hero.on('leave', () => {
  //triggerElementを抜けたとき
});


if(document.querySelector(".recruit-person-main-lineup")) {
  var recruit_person_lineup = new ScrollMagic.Scene({
    triggerElement: ".recruit-person-main-lineup",
    triggerHook: 'onCenter',
    // duration: 800,
    offset: 0
  })
    // .addIndicators()
    .addTo(controller);
  
  recruit_person_lineup.on('enter', () => {
    // $('.recruit-person-main-lineup__item').eq(0).addClass('is-lineup-current');
    var count = 0;
    var max_num = $('.recruit-person-main-lineup__item').length;
    setInterval(function () {
      $('.recruit-person-main-lineup__item').removeClass('is-lineup-current');
      $('.recruit-person-main-lineup__item').eq(count + 1).addClass('is-lineup-current');
      count += 1;
      if (count >= max_num) {
        count = 0;
        $('.recruit-person-main-lineup__item').eq(count).addClass('is-lineup-current');
      }
    }, 3000);
  });
  
  
  var recruit_person_thumbnail = new ScrollMagic.Scene({
    triggerElement: ".recruit-person-main-thumbnail",
    triggerHook: 'onCenter',
    // duration: 800,
    offset: 0
  })
    // .addIndicators()
    .addTo(controller);
  
  recruit_person_thumbnail.on('enter', () => {
    $('.recruit-person-main-thumbnail').addClass('is-active');
  });
}

